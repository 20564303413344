@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}
.help-url {
      margin-left: auto;
}
@media (max-width: 991px) {
.help-url{
      margin-left: unset;
}
}

svg > rect:first-child {
  fill-opacity: 0 !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}

.dz-message {
  text-align: center;
  padding: 30px;
}
.exchange-head {
font-size: 12px;
font-weight: bold;
color :#4d63cf;
}

// kanban board
.react-kanban-board {
  display: inherit !important;
  .react-kanban-column {
    background-color: $white;
    padding: 1.25rem;
    [data-rbd-draggable-context-id] {
      > div {
        display: block !important;
      }
    }
  }
  > div {
    display: flex;
    justify-content: center;
    gap: 25px;
    @media (max-width: 991.98px) {
      flex-direction: column;
      .react-kanban-column {
        width: 100%;
      }
    }
  }
}

.react-kanban-column {
  width: 33.33%;
}

.react-kanban-column > div {
  display: block !important;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}

.task-box {
  border: 1px solid $border-color;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}

.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
}

.input-color {
  color: $input-color !important;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}

.flatpickr-weekdays {
  background: $primary !important;
}

span.flatpickr-weekday {
  background: $primary !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}

span.span-active-order {
      font-size:10px;
      padding-left:5px;
}
span.flatpickr-weekday {
  color: $white !important;
}

.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}

.carousel {
  .control-dots {
    margin: -5px 0px;
  }

  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;

    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }

  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }

  .fc-today-button {
    display: none;
  }
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}

.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width
    auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;

  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}

.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}

.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}

.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}

.rangeslider__handle:focus {
  outline: none;
}

// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}

.search-label {
  float: right;
}

.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}

.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}

.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

/*  tui charts**/
.tui-chart .tui-chart-chartExportMenu-area {
  z-index: 1 !important;
}

//react-table
.custom-header-css {
  thead {
    tr {
      th {
        div:last-child {
          min-height: 38px;
        }
      }
    }

    td:nth-last-child(2) {
      width: 150px !important;
    }
  }
}

.editor_email .ck-content {
  height: 200px;
}

.jobs-categories .card-body a:last-child {
  margin-bottom: 0 !important;
}

@media (max-width: 991.98px) {
  .search-box {
    display: block !important;
    .search-label {
      float: none;
      width: 100%;
      .form-control {
        border-radius: 0.25rem !important;
      }
    }
  }
}

// select

.select2-selection__control {
      background-color: $input-bg !important;
      border-color: $input-border-color !important;
}

.select2-selection__placeholder,
.css-14el2xx-placeholder {
  color: $input-placeholder-color !important;
}

.select2-selection__input-container,
.select2-selection__single-value {
  color: $input-color !important;
}

.select2-selection__menu-list,
.select2-selection__menu {
  background-color: var(--#{$prefix}custom-white) !important;
  border-radius: 1px !important;
}


.select2-selection__menu {
      box-shadow: none !important;
      border: 1px solid $input-border-color !important;
    }
    
    .select2-selection__indicator-separator {
      background-color: $input-border-color !important;
    }
    

.select2-selection__option--is-focused,
.select2-selection__option--is-selected {
  background-color: $primary !important;
  color: $white !important;
}

.select2-selection__group-heading {
  color: $text-muted !important;
}

.form-control__control,
.css-1s2u09g-control {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
}

.form-control__value-container,
.css-319lph-ValueContainer,
.css-g1d714-ValueContainer {
  color: $input-color !important;
}

.css-26l3qy-menu {
  background-color: var(--#{$prefix}custom-white) !important;
  border-radius: 4px !important;
}

.css-1n7v3ny-option{
  background-color: $primary !important;
  color: $white !important;
}

// rdw-editor

.rdw-editor-toolbar,
.rdw-option-wrapper,
.rdw-editor-main {
  border-color: $input-border-color !important;
  background-color: var(--#{$prefix}custom-white) !important;
}

.rdw-option-wrapper {
  border: none !important;
  box-shadow: none !important;
}

.rdw-dropdown-wrapper {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
  box-shadow: none !important;
}

.rdw-dropdown-selectedtext {
  color: var(--#{$prefix}body-color) !important;
}

[data-layout-mode="dark"] {
  .rdw-option-wrapper,
  .rdw-dropdown-selectedtext {
    img {
      filter: invert(1) !important;
    }
  }
}

// ck editor

.ck.ck-toolbar {
  background-color: var(--#{$prefix}light) !important;
  border: 1px solid $input-border-color !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  background-color: var(--#{$prefix}custom-white) !important;
  color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: $input-border-color !important;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  background-color: var(--#{$prefix}light) !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: $input-border-color !important;
}

/* hide input type = number in Chrome, Safari, Edge, Opera */ 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*hide input type = number in  Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/*strangles*/
// Here you can add other styles
table.caption-top caption { margin: 5px;}
table.caption-top .th-grey {
    background-color: rgb(69 69 69);
    color: rgb(255 255 255);
}
table.caption-top .td-vertical-align {
    vertical-align: top;  
    background-color: #454545;  
    color:white;
    font-size: 20px;
    padding: 10px;
    width: 10%;    
}
table.caption-top tbody:hover .td-vertical-align {
    background-color: #d5d9f8;
    color:#000000;
}
table.caption-top .td-vertical-align p {    
    font-size: 12px;    
}

table.caption-top .td-vertical-align p span {    
    font-weight: bolder;    
    font-size: 12px;
}

table.caption-top .td-vertical-align span.text-sm {    
    font-weight: bolder;    
    font-size: 10px;
}

table.caption-top tbody:nth-child(odd) {
    background: #f8f9fa;
}

table.caption-top tbody:hover,
table.caption-top tr.hover,
table.caption-top th.hover,
table.caption-top td.hover,
table.caption-top tr.hoverable:hover
{
    background-color: #dcedfc !important;
}

table.caption-top p.created-date { font-size: 10px; bottom:0; margin: 0;}
.swal2-cancel{
  margin-right: 10px;
}
/*strangles -end*/
tr.alt-row{
  background: #f8f9fa;
}
tr.alt-row-2{
  background-color: #32384b;
}

.chartWrapper {
  position: relative;

}

.chartWrapper > ReactApexChart {
  position: relative;
  left: 0;
  top: 0;
  pointer-events:painted;
}

.chartAreaWrapper {
  overflow-x: scroll;
  overflow: auto;
  overflow-y: hidden;
}

table.caption-top-dark tbody:hover,
table.caption-top-dark tr.hover,
table.caption-top-dark th.hover,
table.caption-top-dark td.hover,
table.caption-top-dark tr.hoverable:hover
{
    background-color: #32384b !important;
    box-shadow: inset 0 0 0 9999px #32384b;
}

table.caption-top-dark tbody:nth-child(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

table.caption-top-dark tbody:hover .td-vertical-align {
  background-color: #bcbdbe;
  box-shadow: inset 0 0 0 9999px #32384b;
  //color:#000000;
}



.table-text-color-light {
  color:#8a90a0;
}


/*NEW CHANGES*/
body {
      .container-fluid {
            width: 85%;
      }
      header#page-topbar {
            background: #2a3042;
            // .navbar-header {
            //       max-width: 1576px;
            // }
      }
      .navbar-brand-box {
            width: 120px!important;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 0;
            a {
                  span.logo-lg {
                        img {
                              width: 185px;
                              object-fit: contain;
                              height: auto;
                        }
                  }
            }
      }
      button#vertical-menu-btn, .noti-icon i, button#page-header-user-dropdown span, button#page-header-user-dropdown i {
            color: #555b6d;
      }
      .topnav {
            background-color: #2a3042!important;
            padding: 20px 10px;
            ul {
                  li {
                        &.active {
                              a {
                                    color: #fff;
                              }
                        }
                        a {
                              color: #a6b0cf;
                              font-size: 13px;
                              display: flex;
                              align-items: center;
                              justify-content: space-between;
                              i {
                                    font-size: 20px;
                                    margin-right: 5px;
                                    height: 20px;
                                    line-height: 20px;
                              }
                        }
                        &:not(:last-child) {
                              margin-right: 35px;
                        }
                  }
            }
      }
      header#page-topbar {
            background: #fff;
      }
      .vertical-menu {
            width: 0px!important;
      }
      .main-content {
            margin-left: 0px!important;
            .page-content {
                  .page-title-box {
                        padding-bottom: 35px;
                  }
            }
            .row {
                  .box_2  {
                        .card {
                              background: transparent;
                              box-shadow: none;
                              .card-body {
                                    .row {
                                          & > div {
                                                div {
                                                      background: #fff;
                                                      &:last-child {
                                                            .row {
                                                                  div {
                                                                        h4 {
                                                                              color: #556ee6;
                                                                        }
                                                                  }     
                                                            }
                                                      }
                                                }
                                          }
                                    }
                              }
                        }
                  }
                  .order_detail {
                        .card {
                              background: #fff;
                              min-height: 95.5%;
                              padding: 20px;
                              .card-body {
                                    padding-right: 0px;
                                    padding-left: 0;
                                    .rounded {
                                          background: #fff;
                                    }
                              }
                        }
                  }
            }
      }
      .footer {
            left: 0px!important;
      }
      &.vertical-collpsed {
            .navbar-brand-box {
                  width: 120px!important;
            }
           .logo {
                  span {
                        &.logo-lg {
                              display: block;
                        }
                        &.logo-sm {
                              display: none;
                        }
                  }
           }
           .vertical-menu {
                  width: 100% !important;
                  height: auto !important;
                  div#sidebar-menu {
                        ul {
                              display: flex;
                              align-items: center;
                              justify-content: flex-start;
                        }
                  }
           }
      }
      div#sidebar-menu {
            ul {
                  li {
                        a {
                              width: 100%;
                              display: flex;
                              align-items: center;
                              justify-content: flex-start;
                              i {
                                    text-align: left;
                              }
                        }
                  }
            }
      }
      .navbar-header {
            .dropdown-mega {
                  button {
                        height: 55px;
                        margin: 10px 10px 10px 0;
                        border-radius: 5px;
                        max-width: 170px;
                        min-width: 170px;
                  }
            }
      }
}
body[data-layout=horizontal][data-layout-mode="dark"] .card.box_4 {
      background-color: #262a3a !important;
      background-image: linear-gradient(45deg, #262a3a 35%, #2a356c) !important;
}
body[data-layout=horizontal][data-layout-mode="dark"]   {
      header#page-topbar {
            background: #222736;
            .navbar-brand-box {
                  .logo-dark {
                        display: none;
                  }
                  .logo-light {
                        display: block;
                  }
            }
      }
      .noti-icon i {
            color: #fff;
      }
      button#page-header-user-dropdown {
            span , i {
                  color: #fff;
            }
      }
      .mini-stats-wid  {
            p {
                  color: #a6b0cf!important;
            }
      }
      .text-muted {
            color: #a6b0cf!important;
      }
      .main-content .row .order_detail .card, .main-content .row .order_detail .card .card-body .rounded, .main-content .row .box_2 .card .card-body .row > div div {
            background: #2a3042;
      }
      .table-responsive .table tbody tr td.table-white {
            background: #222736;
      }
      footer.footer {
            .text-sm-end {
                  a {
                        img {
                              filter: invert(1);
                        }
                  }
            }
      }
      .page-content.common div:first-child .row .col-lg-12 .card .card-body .accordion .brd-soft.accordion-item .accordion-body {
            background: #2a3042;
            border: 1px solid #556ee6;
            box-shadow: 0 0 5px 0 #303034;
        }
        .page-content.common div:first-child .row .col-lg-12 .card .card-body .accordion .brd-soft.accordion-item h2.bg-soft.accordion-header, .page-content.common div:first-child .row .col-lg-12 .card .card-body .accordion .brd-soft.accordion-item .bg-soft.accordion-header.h2 {
            background: transparent;
        }  
  }

  body[data-layout=horizontal] .common .row .col-lg-12 .card .card-body .accordion .accordion-body .wizard .content .tab-content .tab-pane .row .col-lg-4 .card .card-body .card-title {
      padding: 10px 15px 0;
  }

  .transaction {
      .card {
            .p-1.px-3.row {
                  padding-top: 20px!important;
                  padding-bottom: 20px!important;
                  div {
                        div {
                             .d-flex {
                                    h6 {
                                          font-size: 15px;
                                          font-weight: bold;
                                          margin-bottom: 15px!important;
                                    }
                             }
                             .row {
                                    p {
                                          font-size: 13px;
                                          font-weight: 600;
                                          text-align: right;
                                    }
                             }
                        }
                  }
              }
            .card-body {
                  .border-bottom {
                        padding-bottom: 0;
                        .search-box {
                              .form-control {
                                    border-radius: 5px;
                              }
                        }
                        p {
                              span {
                                    span.normal {
                                          font-weight: normal!important;
                                    }
                              }
                        }
                        .row {
                              .col {
                                    display: flex;
                                    align-items: flex-end;
                                    div {
                                          justify-content: flex-start!important;
                                          align-items: flex-end!important;
                                    }
                              }
                              .col-lg-6.col-xxl-4 {
                                    text-align: right;
                              }
                        }
                  }
            }
      }
}


body[data-layout=horizontal] {

      header#page-topbar {
            .navbar-header {
                  .d-flex {
                        .dropdown {
                              display: flex!important;
                              align-items: center;
                              width: 165px;
                              a {
                                    display: flex;
                                    align-items: center;
                                   
                                    i {
                                          font-size: 18px;
                                          font-size: 24px;
                                          line-height: 24px;
                                          margin-right: 10px;
                                    }
                              }
                              
                        }
                        
                  }
            }
      }
      .settings_page {
            .row {
                  div {
                        &:first-child {
                              ul {
                                    li {
                                          a {
                                                width: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: flex-start;
                                                min-height: 70px;
                                                max-height: 70px;
                                                i {
                                                      margin: 0 25px 0 0!important;
                                                }
                                                p {
                                                      margin: 0 !important;
                                                }
                                          }
                                    }
                              }
                        }
                  }
            }
      }
      .straddles {
            .row {
                  .col {
                        span {
                              padding-bottom: 15px;
                        }
                  }
            }
            .card {
                  .card-body {
                        .accordion {
                              .accordion-body {
                                    .wizard {
                                          .steps {
                                                ul {
                                                      li {
                                                            &.current {
                                                                  a {
                                                                        background: #556ee6;
                                                                        color: #fff;
                                                                        span {
                                                                              background: #fff;
                                                                              color: #556ee6;
                                                                        }
                                                                  }
                                                            }
                                                      }
                                                }
                                          }
                                          .content  {
                                                .tab-content {
                                                      .tab-pane {
                                                            .row {
                                                                  .col-lg-4 {
                                                                        .card {
                                                                              .card-header {
                                                                                    background: #556ee5!important;
                                                                                    h5 {
                                                                                          color: #fff!important;
                                                                                    }
                                                                              }
                                                                              .card-body {
                                                                                    ul {
                                                                                          li {
                                                                                                &:nth-child(2n) {
                                                                                                      background: rgba(85, 110, 230, 0.1);
                                                                                                }
                                                                                                b {
                                                                                                      display: inline-block;
                                                                                                      width: 150px;
                                                                                                }
                                                                                          }
                                                                                    }
                                                                              }
                                                                        }
                                                                  }
                                                            }
                                                            form {
                                                                  .row {
                                                                       & > .col-lg-12 {
                                                                               & >  .row {
                                                                                    & > .col-lg-12 {
                                                                                          & > .row {
                                                                                                display: flex;
                                                                                                align-items: inherit;
                                                                                                justify-content: space-between;
                                                                                               & > div {
                                                                                                width: 49%;
                                                                                                border: 1px solid #556ee6 !important;
                                                                                                flex: unset !important;
                                                                                                box-shadow: 0 0 10px 0px #e8e8e8;
                                                                                                border-radius: 10px;
                                                                                                padding: 0px 30px 20px;
                                                                                                }
                                                                                          }
                                                                                    }
                                                                              }
                                                                        }
                                                                  }
                                                            }
                                                      }
                                                }
                                          }
                                          .actions {
                                                ul {
                                                      li {
                                                            a {
                                                                  display: block;
                                                            }
                                                      }
                                                }
                                          }
                                    }
                              }
                        }
                  }
            }
        } 
      .table-responsive {
            .table {
                  //background: #fff;
                  thead {
                        tr {
                              border-width: 0;
                              border-bottom-width: 1px;
                              th {
                                    border: none;
                                    background: #556ee6;
                                    color: #fff;
                              }
                        }
                  }
                  tbody {
                        tr {
                              td {
                                    // background: #fff;
                                    border: none;
                              }
                              // &:nth-child(2n) {
                              //       td {
                              //             background: #eff2f7;
                              //       }
                              // }
                        }
                  }
            }
   
}
      .common {
            .row {
                  .col-lg-12 {
                        .card {
                              .card-body {
                                    .accordion {
                                          .accordion-body {
                                                .wizard {
                                                      .steps {
                                                            ul {
                                                                  li {
                                                                        &.current {
                                                                              a {
                                                                                    background: #556ee6;
                                                                                    color: #fff;
                                                                                    span {
                                                                                          background: #fff;
                                                                                          color: #556ee6;
                                                                                    }
                                                                              }
                                                                        }
                                                                  }
                                                            }
                                                      }
                                                      .content  {
                                                            .tab-content {
                                                                  .tab-pane {
                                                                        .row {
                                                                              .col-lg-4 {
                                                                                    .card {
                                                                                          .card-header {
                                                                                                background: #556ee5!important;
                                                                                                h5 {
                                                                                                      color: #fff!important;
                                                                                                }
                                                                                          }
                                                                                          .card-body {
                                                                                                ul {
                                                                                                      li {
                                                                                                            &:nth-child(2n) {
                                                                                                                  background: rgba(85, 110, 230, 0.1);
                                                                                                            }
                                                                                                            b {
                                                                                                                  display: inline-block;
                                                                                                                  width: 185px;
                                                                                                            }
                                                                                                      }
                                                                                                }
                                                                                          }
                                                                                    }
                                                                              }
                                                                        }
                                                                        form {
                                                                              .row {
                                                                                   & > .col-lg-12 {
                                                                                           & >  .row {
                                                                                                & > .col-lg-12 {
                                                                                                      & > .row {
                                                                                                            display: flex;
                                                                                                            align-items: inherit;
                                                                                                            justify-content: space-between;
                                                                                                           & > div {
                                                                                                            width: 49%;
                                                                                                            border: 1px solid #556ee6 !important;
                                                                                                            flex: unset !important;
                                                                                                            box-shadow: 0 0 10px 0px #e8e8e8;
                                                                                                            border-radius: 10px;
                                                                                                            padding: 0px 30px 20px;
                                                                                                            }
                                                                                                      }
                                                                                                }
                                                                                          }
                                                                                    }
                                                                              }
                                                                        }
                                                                  }
                                                            }
                                                      }
                                                      .actions {
                                                            ul {
                                                                  li {
                                                                        a {
                                                                              display: block;
                                                                        }
                                                                  }
                                                            }
                                                      }
                                                }
                                          }
                                    }
                              }
                        }
                  }
            }
      }
      header#page-topbar {
            .navbar-header {
                  div {
                        &:first-child {
                              width: 82%;
                              justify-content: space-between;
                              .dropdown-mega {
                                    button {
                                          background: transparent!important;
                                          color: #556ee6 !important;
                                          border: 2px solid #556ee6 !important;
                                          height: 48px;                        
                                          padding: 2px 15px;
                                          span {
                                                &.fw-bold {
                                                      text-transform: uppercase;
                                                      font-size: 15px;
                                                }
                                                &.d-block {
                                                      color: #2a3042;
                                                }
                                          }
                                    }
                              }
                        }
                  }
            }}
}

body[data-layout=vertical] {
      &.vertical-collpsed.sidebar-enable {
            #page-topbar {
                  .navbar-brand-box {
                        width: 70px!important;
                        padding: 0;
                        a {
                              &.logo-light {
                                    span.logo-lg {
                                          display: none;
                                    }
                                    span.logo-sm {
                                          display: block;
                                    }
                              }
                        }
                  }
            }
            .vertical-menu {
                  width: 70px!important;
                  position: fixed;
                  div#sidebar-menu {
                        ul {
                              flex-direction: column;
                        }
                  }
            }
            .main-content {
                  margin-left: 70px!important;
            }
      }
      header#page-topbar {
            .navbar-header {
                  .flex-row {
                        .dropdown {
                              display: flex!important;
                              align-items: center;
                              justify-content: space-between;
                              a {
                                    display: flex;
                                    align-items: center;
                                    height: 40px;
                                    width: 50px;
                                    i {
                                          font-size: 18px;
                                          font-size: 24px;
                                          line-height: 24px;
                                          margin-right: 10px;
                                    }
                              }
                        }
                  }
            }
      }
      #page-topbar {
            .navbar-brand-box {
                  width: 250px!important;
                  height: 72px;
                  justify-content: center;
                  box-shadow: 0 0 5px 0 #252832;
            }
      }
      .vertical-menu {
            width: 250px!important;
      }
      .main-content {
            margin-left: 250px!important;
      }
      .container-fluid {
            width: 100%;
      }
      &[data-layout-mode="dark"] {
            .navbar-header {
                  background: #2a3042;
                  .d-flex {
                        button {
                              color: #fff!important;
                              i, span {
                                    color: #fff!important;
                              }
                        }
                        button#page-header-user-dropdown img {
                              background: #fff;
                          }
                  }
            }
            .main-content .row .box_2 .card .card-body .row > div div, .main-content .row .order_detail .card, .main-content .row .order_detail .card .card-body .rounded {
                  background: #2a3042;
              }
              p.text-muted {
                  color: #8891ad!important;
              }
      }
}

.bg-soft{ background: #edf0fd; color: #4d63cf;}
.brd-soft{ border-color: #e0e4f0;}
.rangeslider{background: #f2f2f2;}
.bg-soft button {color: #2a3042;}
.rangeslider .rangeslider__handle{background: #edf0fd; border: 1px solid #edf0fd;}
.rangeslider-horizontal .rangeslider__handle::after{background-color: #556ee6; box-shadow: none;}
.rangeslider, .rangeslider .rangeslider__fill { box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }
.profilehead{ max-width: 95px; min-width: 95px;}
.btn.header-item.noti-icon {width: 50px;height: 40px;display: flex;align-items: center;justify-content: center;}
.profilehead > button {padding-left: 0px; padding-right: 0px;}
body[data-layout="horizontal"] header#page-topbar .navbar-header .d-flex .dropdown > a {height: 40px; width: 50px;}


body[data-layout=horizontal][data-layout-mode="dark"]  {
      .header-profile-user {
            background: #fff;
      }
      .verti-timeline {
            border-color: #99b0c6;
      }
      .box_2 {
            .card {
                  .card-body {
                        .row {
                              .col-lg-12 {
                                    & > div {
                                          background-color: #262a3a !important;
                                          background-image: linear-gradient(45deg, #262a3a 35%, #2a356c) !important;
                                          div {
                                                background: transparent!important;
                                          }
                                    }
                              }
                        }
                  }
            }
      }
      .box_3 {
            .card {
                  background-color: #262a3a !important;
                  background-image: linear-gradient(45deg, #262a3a 35%, #2a356c) !important;
            }     
      }
}

.page-content.common {
      div {
            &:first-child {
                  .row {
                        .col-lg-12 {
                              .card {
                                    background: transparent;
                                    box-shadow: none;
                                    border: none;
                                    .card-body {
                                          padding: 0;
                                          .accordion {
                                                .brd-soft.accordion-item {
                                                      border: none;
                                                      h2.bg-soft.accordion-header {
                                                            width: 200px;
                                                            button {
                                                                  width: 200px;
                                                                  background: url(../images/tab.png) no-repeat right 20px center #556ee6;
                                                                  color: #fff;
                                                                  background-size: 18px;
                                                                  &::after {
                                                                        display: none;
                                                                  }
                                                            }
                                                      }
                                                      .accordion-body {
                                                            background: #fff;
                                                            border: 1px solid #556ee6;
                                                            box-shadow: 0 0 5px 0 #c9cdde;
                                                        }
                                                }
                                          }
                                    }
                              }
                        }
                  }
            }
      }
}

@media(max-width: 1600px) {
      body[data-layout=horizontal] .settings_page .row div:first-child ul li a i {
            margin: 0 10px 0 0 !important;
            font-size: 25px;
        }
}

@media (max-width: 1399px) {
      .transaction .card .card-body .row div.col-lg-6:not(:last-child) {
            margin-bottom: 15px;
      }
      .transaction .card .card-body .row div.col-lg-6 input {
            margin: 0 !important;
      }
}

@media (max-width: 1366px) {
      body[data-layout=vertical] .dropdown-mega {display: none!important;}
}

@media (max-width: 1200px) {
      body .container-fluid, body .container-sm, body .container-md, body .container-lg, body .container-xl, body .container-xxl {
            width: 98%;
      }
}

@media (max-width: 992px) {
      body .main-content {
            margin-left: 0px !important;
        }
        body[data-layout=vertical] .main-content {
            margin-left: 0px !important;
        }  
}

@media (max-width: 991px) {
      body[data-layout=horizontal] header#page-topbar .navbar-header div:first-child {
            justify-content: flex-start;
      }
      body[data-layout=horizontal] header#page-topbar .navbar-header .d-flex .dropdown 
      {
            justify-content: flex-end;
      }
      body .navbar-brand-box {
            width: max-content !important;
      }
      body .topnav {
            padding: 0;
      }
      body .topnav .navbar-collapse {
            padding: 20px 10px;
        }
        body .topnav ul li a {
            justify-content: flex-start;
        }
        body .topnav ul li:not(:last-child) {
            margin-bottom: 15px;
        }
        .transaction .row .col-lg-12 > .row .col-md-3 .card .card-body h4 {
            font-size: 15px;
        }  
        .profilehead {
            max-width: 50px;
            min-width: 50px;
        }
}

@media (max-width: 860px) {
      .transaction .row .col-lg-12 > .row .col-md-3 {
            width: 50%;
      }
}

@media (max-width: 575px) {
      .tab-content > .tab-pane .row .col-sm-6.col-xl-6:not(:last-child) {
            margin-bottom: 15px;
      }
}

@media (max-width: 546px) {
      body[data-layout=vertical] #page-topbar .navbar-brand-box {
            width: 65px!important;
            padding: 0;
      }
      .wizard > .content {
            padding: 14px 0;
      }
      .transaction .row .col-lg-12 > .row .col-md-3 {
            width: 100%;
      }
      body[data-layout=horizontal] header#page-topbar .navbar-header > div:first-child {
            width: 55%;
      }
      .profilehead {
            max-width: 35px;
            min-width: 35px;
      }
      .btn.header-item.noti-icon + input  {
            display: none!important;
      }
      body[data-layout=horizontal] header#page-topbar .navbar-header .d-flex .dropdown {
            justify-content: flex-start;
        }
        body .navbar-brand-box {
            padding: 0;
        }
        body[data-layout=horizontal] header#page-topbar .navbar-header div:first-child .dropdown-mega button {
            display: none;
        }
        body[data-layout=horizontal] header#page-topbar .navbar-header > div:first-child {
            width: 40%;
        }
}
[data-layout-mode="dark"] {
.form-select:disabled{
      background-color: #222736;
}
.form-select{
      border: 1px solid rgb(95, 95, 95) !important;
}
.form-select:focus {
      border: 1px solid #A5AFCF !important;
}
.form-control{
      border: 1px solid rgb(95, 95, 95) !important;
}
.form-control:focus {
      border: 1px solid #A5AFCF!important;
}
.input-group-text{
      border: 1px solid rgb(95, 95, 95) !important;
}
.input-group-text:focus {
      border: 1px solid #A5AFCF !important;
}
.input-border-less{
      border: none;
      color:#F6F6F6
}
.select2-selection__menu {
      box-shadow: none !important;
      border: 1px solid rgb(95, 95, 95) !important;
}
.symbole_filter {
      background-color: #2A3042
}
.select2-selection__indicator-separator {
  background-color: rgb(95, 95, 95) !important;
}
.select2-selection__control {
  background-color: $input-bg !important;
  border: 1px solid rgb(95, 95, 95) !important;
}
}
.mx-auto.d-flex.justify-content-center.col-9.mt-5.row > .col-sm-10.col-xl-10 > .card {
      position: relative;
  }
  .mx-auto.d-flex.justify-content-center.col-9.mt-5.row > .col-sm-10.col-xl-10:first-child > .card::before {
      content: "";
      width: 180px;
      height: 140px;
      position: absolute;
      left: 0px;
      bottom: 0px;
      background: url('../images/delta-exchange.svg') no-repeat center;
      background-size: 200px 130px;
      background-position-y: bottom;
      filter: grayscale(1);
      opacity: 0.3;
  }
  .mx-auto.d-flex.justify-content-center.col-9.mt-5.row > .col-sm-10.col-xl-10:last-child > .card::before {
      content: "";
      width: 180px;
      height: 140px;
      position: absolute;
      left: 0px;
      bottom: 0;
      background: url('../images/Graph.png')no-repeat center;
      background-size: 200px 100px;
      background-position-y: bottom;
      filter: grayscale(1);
      opacity: 0.3;
  }
  .basic-setting-box {
      border: 1px solid #556ee6;
      box-shadow: 0 0 10px 0px #e8e8e8;
      border-radius: 10px;
      margin-bottom: 20px;
  }
//   .mx-auto.d-flex.justify-content-center.col-9.mt-5.row > .col-sm-10.col-xl-10:first-child > .card::after {
//       content: "";
//       width: 150px;
//       height: 80px;
//       position: absolute;
//       right: 0px;
//       top: 0;
//       background: url('../images/delta-exchange.svg') no-repeat center;
//       background-size: contain;
//       filter: grayscale(1);
//       opacity: 0.3;
//   }
//   .mx-auto.d-flex.justify-content-center.col-9.mt-5.row > .col-sm-10.col-xl-10:last-child > .card::after {
//       content: "";
//       width: 150px;
//       height: 80px;
//       position: absolute;
//       right: 20px;
//       top: 0;
//       background: url('../images/spot.png')no-repeat center;
//       background-size: contain;
//       filter: grayscale(1);
//       opacity: 0.3;
//   }
